import React from "react"

import Layout from "../components/layout"

const PrivacyPage = () => (
    <Layout>
        <div className="privacy">
            <h1>Privacy Policy</h1>
            <p className="body-text">Last Updated: Oct 7th, 2020</p>
            <p className="paragraph-text">THIS PRIVACY NOTICE DESCRIBES HOW YOUR INFORMATION MAY BE USED AND DISCLOSED AND HOW YOU CAN ACCESS THIS INFORMATION.</p>
            <h4>What information is collected on the Network and where do we get it?</h4>
            <p className="body-text">
                Information about you may come to us directly through a care coordinator or social worker helping you seek assistance or through a website request form. Information about you may also be disclosed to us by your service providers if they are a member of our Network or by your health plan in the course of trying to get you access to services that they may not directly provide. This information will also be shared within our organization and with other Network Participants to provide you with services. In some situations, we may receive protected health information from a Network Participant, which we may use and disclose only as permitted or required by applicable law. We may also receive personal information about you from third-party analytics providers. Regardless of the source of information, CareAdvisors and its Network Participants are committed to safeguarding your personal information from unlawful use and disclosure.
            </p>
            <p className="body-text">
                To address your needs and connect you to our Network Participants, CareAdvisors may collect and keep a record of information about you. This information may include your name, social security number, telephone number, address and email, age, gender, nationality, ethnicity, physical and mental health condition, health insurance and care team, finances and employment, housing needs, contact information for your family members and caregivers, military background, information about the community programs you have been or are currently enrolled in, and other information that may be required to determine if you are eligible for government benefits, tax credits, income/debt assistance, insurance coverage, housing assistance and other programs and services offered by our Network Participants.
            </p>
            <p className="body-text">
                Some of the information we collect may be considered personal information under federal and/or state privacy laws. CareAdvisors limits who can access your information and takes reasonable steps to prevent third parties from accessing your information inappropriately. Our technology allows us to track the source of the information, who accessed your information, if any disclosures have been made and control what information is shared within the Network.
            </p>
            <h4>Our Website</h4>
            <p className="body-text">
                We maintain a website to provide general information about our services to the public. We collect personal and other information about users over time and across different websites and devices when you use our website. For example, we may collect your name, email address, phone number, the name of your company and the state or region where you reside when you request a demo of our software, request to receive our newsletters or blog updates, fill out a “Contact Us” form, contact us by email or phone, apply for a position at CareAdvisors, or provide us feedback. We also have third parties that collect personal information this way. We, or our vendors, use several common online tracking tools to collect this information. These may include browser and flash cookies, as well as web beacons and similar technologies. Some browsers have a “Do Not Track” feature that lets you inform websites that you do not want to have your online activities tracked. These browser features are not uniform, so we are not currently set up to respond to those signals.
            </p>
            <p className="body-text">
                We use third-party advertising companies to serve ads when you visit our website. These companies use cookies or similar technologies to track visitors to this website and gather demographic information about them. This data is used to deliver customized content and promotions to users whose behavior indicates that they are interested in a particular subject area. Our provision of a link to any other website or location is for your convenience and does not signify our endorsement of such other website or location or its contents.  If you do not want information concerning your website visits to be used for the purposes described above you can send us a request as described below. 
            </p>
            <p className="body-text">
                Notice to California Residents
            </p>
            <p className="body-text">
                This section of our privacy notice applies only to California residents and describes additional rights available to such residents. These rights do not apply to personal information covered by HIPAA or the California Confidentiality of Medical Information Act, publicly available information from government records, de-identified or aggregated information, or personal information covered by certain other privacy laws. We do not sell personal information as the term “sale” is defined under the California Consumer Protection Act. 
            </p>
            <p className="body-text">
                As outlined in our privacy notice, you have the right to request deletion of personal information from our records, subject to applicable law; the right to request information collected about you in the prior twelve months; and the right to request information about the sale or disclosure of your personal information. We reserve the right to request additional information from you to verify your identity in connection with any such request. We will not discriminate against you for exercising any of these rights.  
            </p>
        </div>
    </Layout>
)

export default PrivacyPage